// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

export const loggedOutNavConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  // {
  //   title: 'product',
  //   path: '/dashboard/products',
  //   icon: getIcon('eva:shopping-bag-fill'),
  // },
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: getIcon('eva:file-text-fill'),
  // },
  {
    title: 'login',
    path: '/login',
    icon: getIcon('eva:lock-fill'),
  },
  {
    title: 'FAQ',
    path: '/dashboard/faq',
    icon: getIcon('eva:question-mark-circle-fill'),
  },
  {
    title: 'privacy policy',
    path: '/dashboard/privacy',
    icon: getIcon('eva:eye-fill'),
  },
  {
    title: 'terms of service',
    path: '/dashboard/terms-of-service',
    icon: getIcon('eva:alert-triangle-fill'),
  },
  // {
  //   title: 'register',
  //   path: '/register',
  //   icon: getIcon('eva:person-add-fill'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon('eva:alert-triangle-fill'),
  // },
];

export const loggedInNavConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'profile',
    path: '/dashboard/user',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'Add activity',
    path: '/dashboard/add-activity',
    icon: getIcon('eva:edit-2-fill'),
  },
  {
    title: 'FAQ',
    path: '/dashboard/faq',
    icon: getIcon('eva:question-mark-circle-fill'),
  },
  {
    title: 'privacy policy',
    path: '/dashboard/privacy',
    icon: getIcon('eva:eye-fill'),
  },
  {
    title: 'terms of service',
    path: '/dashboard/terms-of-service',
    icon: getIcon('eva:alert-triangle-fill'),
  },
];
