import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { styled } from '@mui/material/styles';
import { Stack, Box, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// firebase
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
// components
import { FormProvider, RHFTextField } from '../../components/hook-form';

// ----------------------------------------------------------------------

export default function ResetPasswordForm() {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const auth = getAuth();

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address.').required('Email is required.'),
  });

  const defaultValues = {
    email: ''
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    getValues,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async () => {
    sendEmail();
  };

  const ErrorStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: theme.palette.error.light,
  }));

  async function sendEmail() {
    setError('');
    try {
      await sendPasswordResetEmail(auth, getValues('email'));
      navigate('/login');
    } catch(error) {
      switch(error.code) {
        case "auth/invalid-email":
          setError("Invalid email address.");
          break;
        case "auth/user-not-found":
          setError("No user registered with that email address.");
          break;
        default:
          setError('An unexpected error occured.')
      }
    };
}

  useEffect(() => {
    if (auth.currentUser) {
      navigate('/dashboard/app');
    }
  })

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {error &&
          <ErrorStyle>
            <Box>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                Error
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {error}
              </Typography>
            </Box>
          </ErrorStyle>
        }

        <RHFTextField name="email" label="Email address" />
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" sx={{ mt: 3 }} loading={isSubmitting}>
        Reset Password
      </LoadingButton>
    </FormProvider>
  );
}
