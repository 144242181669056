import { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Grid, Container, Typography, Button, Stack, Box, TableContainer, Card, TableRow, Paper, Table, TableHead, TableCell, TableBody, CardHeader } from '@mui/material';
// firebase
import { collection, query, where, doc, getDoc, getDocs, onSnapshot, orderBy, limit } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { db } from "../FirebaseConfig";
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
  AppProgressRadial
} from '../sections/@dashboard/app';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const theme = useTheme();
  const navigate = useNavigate();
  const auth = getAuth();

  const [totalKm, setTotalKm] = useState(0);
  const [fundsRaised, setFundsRaised] = useState(0);
  const [currentKm, setCurrentKm] = useState(0);
  const [participants, setParticipants] = useState(0);
  const [nationalities, setNationalities] = useState([]);
  const [dailyKm, setDailyKm] = useState([]);
  const [kmByCountry, setKmByCountry] = useState([]);
  const [kmByCategory, setKmByCategory] = useState([]);
  const [indivLeaderboard, setIndivLeaderboard] = useState([]);
  const [teamLeaderboard, setTeamLeaderboard] = useState([]);

  const handleLog = useCallback(() => {
    navigate('/dashboard/add-activity');
  })

  useEffect(() => {
    const unsubscribe = onSnapshot(doc(db, "stats", "topMetrics"), (doc) => {
      setTotalKm(Number(doc.data().totalKm.toFixed(2)));
      setFundsRaised(doc.data().fundsRaised);
      setCurrentKm(Number(doc.data().currentKm.toFixed(2)));
      setParticipants(doc.data().participants);
      setNationalities(doc.data().nationalities);
      setKmByCountry(transformMap(mapSort(new Map(Object.entries(doc.data().kmByCountry)))));
      setKmByCategory(transformMap(new Map(Object.entries(doc.data().kmByCategory))));
      setDailyKm(noDecimal(doc.data().dailyKm.slice(0, 30 + (new Date().getDate()))));
      setIndivLeaderboard(doc.data().individualLeaderboard);
      setTeamLeaderboard(doc.data().teamLeaderboard);
    });

    return () => {
      unsubscribe();
    };
  }, [])

  function mapSort(myMap) {
    return new Map([...myMap.entries()].sort((a, b) => a[1] - b[1]));
  }

  function noDecimal(items) {
    return items.map(elem => elem.toFixed(0))
  }

  function removeSmallCategories(arr) {
    const newArr = [];
    let leftOverKm = 0;

    arr.forEach(element => {
      if (element.value >= 0.04 * totalKm || element.label === 'Other') {
        newArr.push(element)
      } else {
        leftOverKm += element.value;
      }
    });

    arr.forEach(element => {
      if (element.label === 'Other') {
        element.value += leftOverKm;
      }
    });

    return newArr;
  }

  const transformMap = (map) => {
    const transformedMap = [];
    map.forEach((values, keys)=>{
      transformedMap.push({label: keys, value: values})
    })
    return transformedMap;
  }

  const ErrorStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: theme.palette.error.light,
  }));

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Hi, welcome back
          </Typography>
          <Button variant="contained" onClick={handleLog} startIcon={<Iconify icon="eva:plus-fill" />}>
            Log Activity
          </Button>
        </Stack>

        {/* <ErrorStyle sx={{ mb: 3 }}>
          <Box>
            <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
              Important Notice
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.primary' }}>
              Due to an error in our database, any activities logged between 5pm CET on the 4th and 3pm CET on 
              the 5th were not recorded and need to be logged again.<br/>If you're unsure whether your activity 
              was successfully logged, please check your Profile page. If an activity that you expect to be 
              there is not there, please log it again. If the actvity is there, do not re-log it.<br/>We 
              apologise for this invonvenience. Keep moving #forwardforconservation!
            </Typography>
          </Box>
        </ErrorStyle> */}

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Kilometres covered" total={totalKm} icon={'mdi:run-fast'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Funds Raised (ZAR)" total={fundsRaised} color="secondary" icon={'mdi:cash'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Nationalities" total={nationalities.length} color="third" icon={'mdi:earth'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            {/* <AppWidgetSummary title="Participants" total={participants} color="fourth" icon={'mdi:account-group'} /> */}
            <AppWidgetSummary title="Participants" total={2612} color="fourth" icon={'mdi:account-group'} />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="Kilometres covered"
              subheader="Total daily km covered by all participants"
              chartLabels={[
                '09/01/2022 GMT',
                '09/02/2022 GMT',
                '09/03/2022 GMT',
                '09/04/2022 GMT',
                '09/05/2022 GMT',
                '09/06/2022 GMT',
                '09/07/2022 GMT',
                '09/08/2022 GMT',
                '09/09/2022 GMT',
                '09/10/2022 GMT',
                '09/11/2022 GMT',
                '09/12/2022 GMT',
                '09/13/2022 GMT',
                '09/14/2022 GMT',
                '09/15/2022 GMT',
                '09/16/2022 GMT',
                '09/17/2022 GMT',
                '09/18/2022 GMT',
                '09/19/2022 GMT',
                '09/20/2022 GMT',
                '09/21/2022 GMT',
                '09/22/2022 GMT',
                '09/23/2022 GMT',
                '09/24/2022 GMT',
                '09/25/2022 GMT',
                '09/26/2022 GMT',
                '09/27/2022 GMT',
                '09/28/2022 GMT',
                '09/29/2022 GMT',
                '09/30/2022 GMT',
                '10/01/2022 GMT',
                '10/02/2022 GMT',
                '10/03/2022 GMT',
                '10/04/2022 GMT',
                '10/05/2022 GMT',
                '10/06/2022 GMT',
                '10/07/2022 GMT',
                '10/08/2022 GMT',
                '10/09/2022 GMT',
                '10/10/2022 GMT',
                '10/11/2022 GMT',
                '10/12/2022 GMT',
                '10/13/2022 GMT',
                '10/14/2022 GMT',
                '10/15/2022 GMT',
                '10/16/2022 GMT',
                '10/17/2022 GMT',
                '10/18/2022 GMT',
                '10/19/2022 GMT',
                '10/20/2022 GMT',
                '10/21/2022 GMT',
                '10/22/2022 GMT',
                '10/23/2022 GMT',
                '10/24/2022 GMT',
                '10/25/2022 GMT',
                '10/26/2022 GMT',
                '10/27/2022 GMT',
                '10/28/2022 GMT',
                '10/29/2022 GMT',
                '10/30/2022 GMT',
                '10/31/2022 GMT',
              ]}
              chartData={[
                // {
                //   name: 'Running',
                //   type: 'column',
                //   fill: 'solid',
                //   data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                // },
                {
                  name: 'Total',
                  type: 'area',
                  fill: 'gradient',
                  data: dailyKm,
                },
                // {
                //   name: 'Swimming',
                //   type: 'line',
                //   fill: 'solid',
                //   data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                // },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppProgressRadial
              title="Progress towards goal"
              subheader="Km covered and funds raised"
              totalKm={totalKm}
              currentKm={currentKm}
              fundsRaised={fundsRaised}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              title="Total km by country"
              subheader="Km covered in countries where activity takes place"
              chartData={kmByCountry}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Activity categories"
              subheader="Km completed by activity type"
              chartData={removeSmallCategories(kmByCategory)}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.secondary.main,
                theme.palette.third.main,
                theme.palette.fourth.main,
                theme.palette.error.light,
                theme.palette.success.light,
                theme.palette.chart.orange,
                theme.palette.chart.blue,
                theme.palette.chart.beige,
                theme.palette.chart.brown,
                theme.palette.chart.nude,
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <Card sx={{ mb: 3 }}>
              <CardHeader title='Individual leaderboard' subheader='Top 10 individual participants' />
              <Box sx={{ p: 3, pb: 1 }} dir="ltr">
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell align="right">Distance (km)</TableCell>
                        <TableCell align="right">Nationality</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {indivLeaderboard.map((row) => (
                        <TableRow
                          key={row.name}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            {row.name}
                          </TableCell>
                          <TableCell align="right">{Math.round(row.km)}</TableCell>
                          <TableCell align="right">{row.country}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Card>
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <Card sx={{ mb: 3 }}>
              <CardHeader title='Team leaderboard' subheader='Top 5 teams' />
              <Box sx={{ p: 3, pb: 1 }} dir="ltr">
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell align="right">Distance (km)</TableCell>
                        <TableCell align="right">Nationality</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {teamLeaderboard.map((row) => (
                        <TableRow
                          key={row.name}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            {row.name}
                          </TableCell>
                          <TableCell align="right">{Math.round(row.km)}</TableCell>
                          <TableCell align="right">{row.country}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Card>
          </Grid>

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="News Update"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `/static/mock-images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  '1983, orders, $4220',
                  '12 Invoices have been paid',
                  'Order #37745 from September',
                  'New order placed #XF-2356',
                  'New order placed #XF-2346',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite
              title="Traffic by Site"
              list={[
                {
                  name: 'FaceBook',
                  value: 323234,
                  icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} height={32} />,
                },
                {
                  name: 'Google',
                  value: 341212,
                  icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} height={32} />,
                },
                {
                  name: 'Linkedin',
                  value: 411213,
                  icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} height={32} />,
                },
                {
                  name: 'Twitter',
                  value: 443232,
                  icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} height={32} />,
                },
              ]}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppTasks
              title="Tasks"
              list={[
                { id: '1', label: 'Create FireStone Logo' },
                { id: '2', label: 'Add SCSS and JS files if required' },
                { id: '3', label: 'Stakeholder Meeting' },
                { id: '4', label: 'Scoping & Estimations' },
                { id: '5', label: 'Sprint Showcase' },
              ]}
            />
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
}
