import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { useTheme, styled } from '@mui/material/styles';
import { Container, Typography, Stack, Box } from '@mui/material';
// firebase
import { getAuth, sendEmailVerification } from "firebase/auth";
import { collection, Timestamp, addDoc } from "firebase/firestore";
import { db } from "../FirebaseConfig";
// components
import { FormProvider, RHFTextField, RHFSelect, RHFDatePicker, RHFCheckbox } from '../components/hook-form';
import Page from '../components/Page';
import { countryList } from '../utils/Countries';
import { activityList } from '../utils/Activities';

// ----------------------------------------------------------------------

export default function LogActivity() {
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const activities = activityList;
  const countries = countryList;
  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    auth.onAuthStateChanged(currentUser => {
      if (currentUser && !currentUser.emailVerified) {
        alert('Your email must be verified before you can log activities. We\'ve just sent you a fresh verification email now. Please follow the instructions in that email and then return to this page and refresh it before trying again.')
        navigate('/');
        sendEmailVerification(auth.currentUser);
      } else if (!currentUser) {
        navigate('/login');
      }
    })
  })

  const RegisterSchema = Yup.object().shape({
    activity: Yup.string().required('Activity required'),
    country: Yup.string().required('Country required'),
    date: Yup.string().required('Date required'),
    distance: Yup.number('Must be a number').typeError('Must be a number').required('Distance required').positive('Must be greater than or equal to 1').lessThan(1000),
  });

  const defaultValues = {
    activity: '',
    country: '',
    date: '',
    distance: '',
    isTeam: false,
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    getValues,
    reset,
    formState: { isSubmitting },
  } = methods;

  function seconds() {
    const dateString = getValues("date");
    const year = dateString.substring(0, 4);
    const month = dateString.substring(5, 7);
    const day = dateString.substring(8);
    return new Date(Date.UTC(year, month - 1, day));
  }

  const onSubmit = async () => {
    setError('Submissions are closed.')
    // if (seconds() >= new Date()) {
    //   setError('The date cannot be in the future');
    // } else {
    //   try {
    //     addActivityToDb();
    //     reset();
    //     setSuccess('Activity logged.');
    //   } catch {
    //     setError('An error occured.')
    //     setSuccess('');
    //   }
    // }
  };

  async function addActivityToDb() {
    await addDoc(collection(db, "sessions"), {
        activity: getValues('activity'),
        country: getValues("country"),
        date: Timestamp.fromDate(seconds(), 0),
        distance: Number(getValues('distance')),
        userId: auth.currentUser.uid,
        isTeamActivity: getValues('isTeam'),
    });
  }

  const ErrorStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: theme.palette.error.light,
  }));

  const SuccessStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: theme.palette.success.light,
  }));

  return (
    <Page title="Log Activity">
      <Container maxWidth="xl">
        <Typography variant="h4" gutterBottom>
          Log a new activity
        </Typography>

        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            {error &&
              <ErrorStyle>
                <Box>
                  <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                    Error
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {error}
                  </Typography>
                </Box>
              </ErrorStyle>
            }
            {success &&
              <SuccessStyle>
                <Box>
                  <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                    Success!
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {success}
                  </Typography>
                </Box>
              </SuccessStyle>
            }

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <RHFTextField name="distance" label="Distance (km)" />
              <RHFDatePicker name="date" label="Date" InputLabelProps={{ shrink: true }} />
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <RHFSelect name="activity" label="Activity" items={activities} />
              <RHFSelect name="country" label="Country" items={countries} />
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="left">
              <RHFCheckbox name="isTeam" checked />
              <Typography variant="body2" align="center" sx={{ color: 'text.secondary', ml: -2 }}>
                This is a team activity
              </Typography>
            </Stack>

            <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
              Submit
            </LoadingButton>
          </Stack>
        </FormProvider>
      </Container>
    </Page>
  );
}
