import React from 'react'
// @mui
import { LoadingButton } from '@mui/lab';
import { useTheme, styled } from '@mui/material/styles';
import { Container, Typography, Stack, Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
// components
import Page from '../components/Page';
//
import Iconify from '../components/Iconify';

const FAQ = () => {

  function SimpleAccordion(props) {
    return (
      <Accordion>
          <AccordionSummary
            expandIcon={
              <Iconify
                icon={'eva:arrow-ios-downward-fill'}
                sx={{ width: 20, height: 20}}
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
          <Typography>{props.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {props.answer}
            </Typography>
          </AccordionDetails>
        </Accordion>
    )
  }

  return (
    <Page title="FAQ">
      <Container maxWidth="xl">
        <Typography variant="h4" gutterBottom>
          Frequently Asked Questions
        </Typography>

        {/* <Typography variant="h5" gutterBottom>
          Using the website
        </Typography> */}

        <SimpleAccordion
          question="Why do I need to verify my email address to log activities?" 
          answer="This ensures that users are who they say they are and prevents our database from becoming overloaded by activities logged with fake email addresses."
        />

        {/* <Typography variant="h5" gutterBottom>
          About the challenge
        </Typography> */}

        <SimpleAccordion
          question="Do I need to be sponsored to participate?" 
          answer="No. You can log activities and be part of the challenge 
          without making a donation."
        />
        <SimpleAccordion
          question="Can I make a donation if I'm not participating?"
          answer="Yes. You do not need to be participating yourself, or 
          sponsoring someone, to donate."
        />
        <SimpleAccordion
          question="When does the challenge end?"
          answer="The challenge runs from the 1st of September to the 
          25th of September 2022. You will only be able to log activities 
          within these dates."
        />
        <SimpleAccordion
          question="What are the goals of the challenge?"
          answer="The goals of the challenge are for participants' total 
          cumulative distance logged to go around the world five times 
          (200,375 km), and to raise R1,000,000 for conservation."
        />
        <SimpleAccordion
          question="Where can I register?"
          answer="You can register on this website! Just click the profile 
          icon in the top right corner, then Login > Get started."
        />
        <SimpleAccordion
          question="Where can I donate?"
          answer="You can access Tshembo's Charity Saver web page by clicking Donate 
          on this website, or visit https://www.tshemboafricafoundation.com/donate."
        />
        <SimpleAccordion
          question="What if I walk with a pet?"
          answer="If you exercise with a pet, you can log their kilometres too. Do not 
          create a separate account for your pet. When logging an activity, log yours 
          separately to your pets', selecting the 'Pet' option in the activity dropdown."
        />
        <SimpleAccordion
          question="What if we're a company/team?"
          answer="Create a single account as if you were an individual. Then, when 
          logging an activity, select the 'This is a team activity' checkbox."
        />
        <SimpleAccordion
          question="Can I log different categories each day?" 
          answer="During the challenge you will be able to log different 
          categories of activity, whatever you did for the day. As an example, 
          if you are cycling to work in the morning and go for a jog in the evening 
          you will log the km for the cycle and the km for the run as separate 
          activities."
        />
        <SimpleAccordion
          question="Do I need to update my progress every day?"
          answer="You do not necessarily need to log your daily progress 
          every day, but as often as possible so we can see the overall 
          progress of the challenge. When you log an activity, you will need 
          to select the date it was completed on."
        />
        <SimpleAccordion
          question="What if my activity isn't on the list?"
          answer="Select 'Other' or email mail@tshembo.com and we may choose 
          to add it."
        />
      </Container>
    </Page>
  )
}

export default FAQ