import * as React from 'react';
import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { FormControlLabel, FormGroup, Select, InputLabel, FormControl, MenuItem } from '@mui/material';

// ----------------------------------------------------------------------

RHFSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.array
};

export default function RHFSelect({ name, label, items, ...other }) {
  const [age, setAge] = React.useState('');
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">{label}</InputLabel>
          <Select
            label={label}
            // value={age}
            {...field}
            value={field.value}
            error={!!error}
            {...other}
          >
            {items.map(item => (<MenuItem value={item} key={item}>{item}</MenuItem>))}
          </Select>
        </FormControl>
      )}
    />
    // <FormControl fullWidth>
    //   <InputLabel id="demo-simple-select-label">Age</InputLabel>
    //   <Select
    //     labelId="demo-simple-select-label"
    //     id="demo-simple-select"
    //     value={age}
    //     label="Age"
    //   >
    //     <MenuItem value={'male'}>Male</MenuItem>
    //     <MenuItem value={'female'}>Female</MenuItem>
    //     <MenuItem value={'other'}>Other</MenuItem>
    //   </Select>
    // </FormControl>
  );
}