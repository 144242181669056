import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  Paper
} from '@mui/material';
// firebase
import { collection, query, where, doc, getDoc, getDocs, onSnapshot } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { db } from "../FirebaseConfig";
// components
import Page from '../components/Page';
import { UserMoreMenu } from '../sections/@dashboard/user';

// ----------------------------------------------------------------------

export default function User() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [sex, setSex] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const [nationality, setNationality] = useState('');
  const [km, setKm] = useState(0);
  const [petKm, setPetKm] = useState(0);
  const [activities, setActivities] = useState([]);
  const [accountType, setAccountType] = useState('');
  
  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    let unsubscribe;
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate('/login');
      } else {
        const uid = user.uid;
  
        getDoc(doc(db, "users", uid)).then(docSnap => {
          if (docSnap.exists()) {
            setName(user.displayName);
            setEmail(user.email);
            setIsVerified(capitalizeFirstLetter(String(user.emailVerified)));
            setSex(capitalizeFirstLetter(docSnap.data().sex));
            setNationality(docSnap.data().country);
            setKm(docSnap.data().km);
            setPetKm(docSnap.data().petKm);
            docSnap.data().isTeam ? setAccountType('Team') : setAccountType('Individual');
          } else {
            console.log("No such document!");
          }
        })

        const sessionsRef = collection(db, "sessions");
        const q = query(sessionsRef, where("userId", "==", uid));

        unsubscribe = onSnapshot(q, (querySnapshot) => {
          const newDocs = [];
          querySnapshot.docs.forEach(doc => {
            const item = doc.data();
            item.id = doc.id;
            newDocs.push(item);
          });
          setActivities(newDocs);
        });
      }
    });
    return () => {
      unsubscribe();
    };
  }, [])
  
  const profileRows = [{ name, email, sex, isVerified, nationality, accountType }];

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function toDateTime(secs) {
    const t = new Date(1970, 0, 1);
    t.setSeconds(secs);
    return t;
  }

  return (
    <Page title="Profile">
      <Container>
        <Typography variant="h4" gutterBottom>
          Profile
        </Typography>
        <Typography variant="h5" gutterBottom>
          Personal info
        </Typography>

        <Card sx={{ mb: 3 }}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="right">Email</TableCell>
                  <TableCell align="right">Email Verified</TableCell>
                  <TableCell align="right">Sex</TableCell>
                  <TableCell align="right">Nationality</TableCell>
                  <TableCell align="right">Account Type</TableCell>
                  {/* <TableCell align="right" /> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {profileRows.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="right">{row.email}</TableCell>
                    <TableCell align="right">{row.isVerified ? 'Yes' : 'No'}</TableCell>
                    <TableCell align="right">{row.sex}</TableCell>
                    <TableCell align="right">{row.nationality}</TableCell>
                    <TableCell align="right">{row.accountType}</TableCell>
                    {/* <TableCell align="right">
                      <UserMoreMenu />
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>

        <Typography variant="h5" gutterBottom>
          Your activities
        </Typography>

        <Typography variant="body" gutterBottom>
          You have logged <b>{km}</b> km and <b>{petKm}</b> pet km.
        </Typography>

        <Card sx={{ mb: 3, mt: 2 }}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Activity</TableCell>
                  <TableCell align="right">Distance (km)</TableCell>
                  <TableCell align="right">Date</TableCell>
                  <TableCell align="right">Country</TableCell>
                  <TableCell align="right">Activity Type</TableCell>
                  <TableCell align="right" />
                </TableRow>
              </TableHead>
              <TableBody>
                {activities.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.activity}
                    </TableCell>
                    <TableCell align="right">{row.distance}</TableCell>
                    <TableCell align="right">{toDateTime(row.date.seconds).toDateString()}</TableCell>
                    <TableCell align="right">{row.country}</TableCell>
                    <TableCell align="right">{row.isTeamActivity ? 'Team' : 'Individual'}</TableCell>
                    <TableCell align="right">
                      <UserMoreMenu data={row.id} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>

      </Container>
    </Page>
  );
}
