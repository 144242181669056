import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Card, CardHeader } from '@mui/material';

// ----------------------------------------------------------------------

AppProgressRadial.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  totalKm: PropTypes.number.isRequired,
  currentKm: PropTypes.number.isRequired,
  fundsRaised: PropTypes.number.isRequired,
};

export default function AppProgressRadial({ title, subheader, chartLabels, totalKm, currentKm, fundsRaised, ...other }) {
  const theme = useTheme();

  const state = {
    series: [(totalKm / 200375 * 100).toFixed(3), (fundsRaised / 1000000  * 100).toFixed(3), (currentKm / 40075 * 100).toFixed(3)],
    options: {
      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: "center",
        floating: true,
      },
      colors: [theme.palette.primary.main, theme.palette.secondary.main, theme.palette.third.main],
      chart: {
        height: 350,
        type: 'radialBar'
      },
      plotOptions: {
        radialBar: {
          dataLabels: {
            name: {
              fontSize: '14px',
            },
            value: {
              fontSize: '16px',
            },
            total: {
              show: false,
              label: 'Total',
            }
          }
        }
      },
      fill: {
        type: 'solid',
        colors: [theme.palette.primary.main, theme.palette.secondary.main, theme.palette.third.main]
      },
      labels: ['Total km (Goal: 200,375 km)', 'Funds raised  (Goal: R1,000,000)', 'Current trip around world (Goal: 40,075 km)'],
    },
  };

  const CHART_HEIGHT = 403;
  const LEGEND_HEIGHT = 72;

  const ChartWrapperStyle = styled('div')(({ theme }) => ({
    height: CHART_HEIGHT,
    marginTop: theme.spacing(1),
    '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
    '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
      overflow: 'visible',
    },
    '& .apexcharts-legend': {
      height: LEGEND_HEIGHT,
      alignContent: 'center',
      position: 'relative !important',
      borderTop: `solid 1px ${theme.palette.divider}`,
      top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
    },
  }));

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />
      <ChartWrapperStyle dir="ltr">
        <ReactApexChart options={state.options} series={state.series} type="radialBar" height={350} />
      </ChartWrapperStyle>
    </Card>
  );
}
