export const activityList = [
  "Running",
  "Walking",
  "Hiking",
  "Cycling",
  "Canoeing",
  "Kayaking",
  "Rowing",
  "Surfing",
  "Kitesurfing",
  "Windsurfing",
  "Swimming",
  "Skiing",
  "Snowboarding",
  "Skateboarding",
  "Rock climbing",
  "Wheelchair",
  "Horse riding",
  "Pet's kilometres",
  "Other"
]