import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { styled } from '@mui/material/styles';
import { Stack, IconButton, InputAdornment, Box, Link, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// firebase
import { addDoc, doc, setDoc, collection } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification, updateProfile } from "firebase/auth";
import { db } from "../../../FirebaseConfig";
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFSelect, RHFCheckbox } from '../../../components/hook-form';
import { countryList } from '../../../utils/Countries';

// ----------------------------------------------------------------------

export default function RegisterForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [tocError, setTocError] = useState('');
  const auth = getAuth();
  const sexes = ['Male', 'Female', 'Other'];
  const currentUser = auth.currentUser;

  useEffect(() => {
    auth.onAuthStateChanged(currentUser => {
      if (currentUser) {
        navigate('/');
      }
    })
  })

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().required('First name required'),
    lastName: Yup.string().required('Last name required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    nationality: Yup.string().required('Nationality is required'),
    sex: Yup.string().required('Sex is required'),
    password: Yup.string().matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      "Must contain at least 8 characters, one uppercase, one lowercase, one number and one special case character."
    ).required('Password is required'),
    confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], "Passwords don't match.").required('Password confirmation is required'),
    // accepted: Yup.bool().oneOf([true], "You must accept the T&Cs and Privacy Policy.").default(false),
  });

  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    sex: '',
    nationality: '',
    password: '',
    confirmPassword: '',
    accepted: false,
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  async function addToSendinBlue(uid) {
    try {
      fetch('https://api.sendinblue.com/v3/contacts', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Api-Key': process.env.REACT_APP_SENDINBLUE_API_KEY,
        },
        body: JSON.stringify({
            'email': getValues("email"),
            'attributes': {
                'FIRSTNAME': getValues("firstName"),
                'LASTNAME': getValues("lastName")
            },
            'emailBlacklisted': false,
            'smsBlacklisted': false,
            'listIds': [
                11
            ],
            'updateEnabled': false,
        })
      });
    } catch(err) {
      await addDoc(collection(db, "manualLogs"), {
        error: err.message,
        id: uid
      })
    }
  }

  function createUserInDB(id) {
    setDoc(doc(db, "users", id), {
        country: getValues("nationality"),
        name: `${getValues("firstName")} ${getValues("lastName")}`,
        sex: getValues("sex"),
        km: 0,
        petKm: 0
    });
  }

  async function registerUser() {
    setError('');
    await createUserWithEmailAndPassword(auth, getValues("email"), getValues("password"))
      .then((userCredential) => {
          const id = userCredential.user.uid;
          updateProfile(userCredential.user, {
              displayName: `${getValues("firstName")} ${getValues("lastName")}`
          })
          createUserInDB(id);
          sendEmailVerification(userCredential.user);
          addToSendinBlue(getValues("email"));
      })
      .catch((error) => {
          if (error.code === 'auth/email-already-exists' || error.code === 'auth/email-already-in-use') {
            setError('A user with that email address is already registered.');
          } else if (error.code === 'auth/weak-password') {
            setError('Password must be at least 6 characters long.');
          } else {
            setError('Failed to log in.');
          }
      });
      // .then(navigate('/dashboard/app'));
  }

  const {
    handleSubmit,
    getValues,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    if (!getValues('accepted')) {
      setTocError('You must accept the T&Cs and Privacy Policy.')
    } else {
      setTocError('');
      registerUser();
    }
  };

  const ErrorStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: theme.palette.error.light,
  }));

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {error &&
          <ErrorStyle>
            <Box>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                Error
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {error}
              </Typography>
            </Box>
          </ErrorStyle>
        }
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFTextField name="firstName" label="First name" />
          <RHFTextField name="lastName" label="Last name" />
        </Stack>

        <RHFTextField name="email" label="Email address" />

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFSelect name="nationality" label="Nationality" items={countryList} />
          <RHFSelect name="sex" label="Sex" items={sexes} />
        </Stack>

        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <RHFTextField
          name="confirmPassword"
          label="Confirm Password"
          type={showPassword ? 'text' : 'password'}
        />

        <Stack direction="row" alignItems="center" justifyContent="left">
          <RHFCheckbox name="accepted" checked />
          <Typography variant="body2" align="center" sx={{ color: 'text.secondary', ml: -2 }}>
              I agree to the&nbsp;
              <Link underline="always" color="text.primary" href="/dashboard/terms-of-service">
                Terms of Service
              </Link>
              {''} and {''}
              <Link underline="always" color="text.primary" href="/dashboard/privacy">
                Privacy Policy
              </Link>
              .
            </Typography>
        </Stack>

        {tocError && 
          <Typography variant="body" sx={{ color: 'text.error' }}>
            {tocError}
          </Typography>
        }

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Register
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
