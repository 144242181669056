import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { styled } from '@mui/material/styles';
import { Stack, IconButton, InputAdornment, Box, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// firebase
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const auth = getAuth();
  const currentUser = auth.currentUser;

  useEffect(() => {
    auth.onAuthStateChanged(currentUser => {
      if (currentUser) {
        navigate('/');
      }
    })
  })

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address.').required('Email is required.'),
    password: Yup.string().required('Password is required.'),
  });

  const defaultValues = {
    email: '',
    password: ''
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    getValues,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async () => {
    logIn();
  };

  const ErrorStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: theme.palette.error.light,
  }));

  async function logIn() {
    setError('');
      await signInWithEmailAndPassword(auth, getValues('email'), getValues('password'))
        .catch((error) => {
          if (error.code === 'auth/user-not-found') {
            setError('There is no registered user with that email address');
          } else if (error.code === 'auth/wrong-password') {
            setError('Incorrect password');
          } else {
            setError('Failed to log in');
          }
        });
        // .then(navigate('/dashboard/app'));
  }

  if (auth.currentUser) {
    return <Navigate to="/" replace />;
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {error &&
          <ErrorStyle>
            <Box>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                Error
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {error}
              </Typography>
            </Box>
          </ErrorStyle>
        }

        <RHFTextField name="email" label="Email address" />

        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" sx={{ mt: 3 }} loading={isSubmitting}>
        Login
      </LoadingButton>
    </FormProvider>
  );
}
